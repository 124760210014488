/**
 * MainContent.css
 * kbsoft-webapp
 *
 * Created by Christian Jungbluth on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 *
 */

.main_content_area {
    height: 100%;
    width: 100%;
    background: var(--main_ci_backgroundgrey);
}
