.notification_center_area {
    position: fixed;
    bottom: 0px;
    right: 0px;
    z-index: 900;
}
/* The span element is hosted by the React Transition group*/
.notification_center_area > span {
    display: flex;
    flex-direction: column-reverse;
}

.notification_item {
    min-height: 86px;
    width: 320px;
    /* border: 1px solid black; */
    margin: 0 10px 10px 0px;
    display: flex;
    background-color: #ececec;
    box-shadow: 0 0 10px 0px #0000005e;
    border-radius: 2px;
    transition: all 1s ease-in-out;
}

.notification_item_icon {
    height: 80px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.notification_item_icon_info {
    color: #17943c;
}
.notification_item_icon_warn {
    color: #ffa600;
}
.notification_item_icon_error {
    color: #8d0000;
}

.notification_item_icon > i {
    margin-top:9px;
    font-size: 42px;
}

.notification_text_container {
    /* border: 1px solid black; */
    flex: 1;
    padding: 2px 2px 20px 0px;
}

.notification_close_btn_container {
    display: flex;
    justify-content: flex-end;
}

.notification_content {
    font-size: 14px;
    padding: 0 20px 0 0;
    display: block;
}

/*ANIMATIONS WITH CSSTRANSITIONGROUPS!*/
.notification-enter {
    transform: translateX(330px);
}

.notification-enter.notification-enter-active {
    transform: translateX(0);
    transition: transform 400ms ease-in-out;
}

.notification-leave {
    transform: translateY(0);
}

.notification-leave.notification-leave-active {
    transform: translateX(330px);
    transition: transform 400ms ease-in-out;
}
