/**
 * Loader.css
 * kbsoft-webapp
 * 
 * Created by Tim Flauger on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 * 
 */
.spinner_parent {
    position: absolute;
    top: 0;
    left: 0; 
    height: 100%;
    width: 100%;
    z-index: 1001;
    background: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.spinner {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items:center;
    font-family: 'arial', sans-serif;
    font-weight: 600;
    font-size: 24px;
    color: #fff;
    background-image: linear-gradient(to right bottom,
            var(--main_ci_color) 0%,
            var(--main_ci_color2, 0.1));
    -webkit-animation: sk-rotateplane 3s infinite ease-in-out;
    animation: sk-rotateplane 3s infinite ease-in-out;
}

.spinner_headline {
	height: 30px;
	font-size: 24px;
    padding: 15px 0 0 0;
}
.spinner_subline {
    height: 30px;
    padding: 15px 0 0 0;
}

@-webkit-keyframes sk-rotateplane {
    0% {
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
    }

    25% {
        -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
    }

    50% {
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }

    75% {
        -webkit-transform: perspective(120px) rotateX(-359.9deg) rotateY(-179.9deg);
    }

    100% {
        -webkit-transform: perspective(120px) rotateX(-359.9deg) rotateY(-359.9deg);
    }
} 

@keyframes sk-rotateplane {
    0% {
        transform: perspective(120px) 
        rotateX(0deg) 
        rotateY(0deg);
        -webkit-transform: perspective(120px) 
        rotateX(0deg) 
        rotateY(0deg)
    }

    25% {
        transform: perspective(120px) 
        rotateX(-180.1deg) 
        rotateY(0deg);
        -webkit-transform: perspective(120px) 
        rotateX(-180.1deg) 
        rotateY(0deg)
    }

    50% {
        transform: perspective(120px) 
        rotateX(-180deg) 
        rotateY(-179.9deg);
        -webkit-transform: perspective(120px) 
        rotateX(-180deg) 
        rotateY(-179.9deg);
    }
    75% {
        transform: perspective(120px) 
        rotateX(-359.9deg) 
        rotateY(-179.9deg);
        -webkit-transform: perspective(120px) 
        rotateX(-359.9deg) 
        rotateY(-179.9deg);
    }
    100% {
        transform: perspective(120px) 
        rotateX(-359.9deg)
        rotateY(-359.9deg);
        -webkit-transform: perspective(120px) 
        rotateX(-359.9deg)
        rotateY(-359.9deg);
    }
}