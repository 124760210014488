/**
 * App.css
 * kbsoft-webapp
 *
 * Created by Christian Jungbluth on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 *
 */

@font-face {
    font-family: "Roboto";
    font-style: normal;
    src: url("../src/assets/fonts/Roboto-Regular.ttf");
}

@font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url("../src/assets/fonts/material-font.woff2") format("woff2");

}

.material-icons {
    font-family: "Material Icons", sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
}

:root {
    --main_ci_backgroundgrey: #f1f4f7;
    --main_ci_color: #222c78;
    --main_ci_color2: #3588ac;
    --main_ci_color3: #9fbac6;
    --main_ci_grey: rgba(128, 128, 128, 0.507);
    --main_ci_grey_text: rgba(56, 56, 56, 0.9);
    --main_ci_hover: #698d9d;
    --main_ci_red: #fac8bf;
    --main_ci_red_hover: #fcacac;
    --main_ci_doughnut1: #75bfca;
    --main_ci_doughnut2: #a6daef;
    --main_ci_warning: #ffd8d1;
}

.frame_content {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    min-height: 720px;
    min-width: 1024px;
}

.userpic_basic {
    height: 35px;
    width: 35px;
    border-radius: 12px;
}

.basic_btn {
    padding: 14px 5px 13px 5px;
    font-size: 12px;
    background: var(--main_ci_color);
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    text-decoration: none;
}

.basic_btn:hover {
    background: var(--main_ci_hover);
}

.basic_btn:focus {
    outline: none;
}

.basic_btn_icon {
    background-color: transparent;
    border: none;
    color: var(--main_ci_grey);
    cursor: pointer;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-content: center;
}

.basic_btn_icon i {
    font-size: 20px;
}

.basic_btn_icon:focus {
    outline: none;
}

.basic_btn_icon:hover {
    color: var(--main_ci_grey_text);
}

/* ----------Scrollbar------------*/

.scrollvisible::-webkit-scrollbar {
    width: 6px;
}

.scrollvisible::-webkit-scrollbar-track {
    background: transparent;
}

.scrollvisible::-webkit-scrollbar-thumb {
    background: var(--main_ci_grey);
    border-radius: 25px;
}

.scrollvisible::-webkit-scrollbar-thumb:hover {
    background: #ccc;
}

/* ----------Forms------------*/

.select {
    min-width: 150px;
    padding: 5px 10px 5px 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.select:focus {
    outline: none;
}
