/**
 * NoMatch.css
 * kbsoft-webapp
 * 
 * Created by Tim Flauger on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 * 
 */
.nomatch_content_area{
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nomatch_content{
    display: flex;
    flex-direction: row;
    margin: 10px 0 0 0;
}

.nomatch_icon{
    margin: 0 10px 0 0;
}

.nomatch_text_area{
    font-size: 12px;
    color: #222c78;
    display: flex;
    flex-direction: column;
}

.nomatch_text{
    margin: 0 0 25px 0;
}

.nomatch_btn_area{
    display: flex;
    justify-content: flex-end;
}

.nomatch_btn{
    width: 150px;
    height: 40px;
    text-decoration: none;
    background: #222c78;
    color: white;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nomatch_btn:hover{
    background: #222c78dc;
    
}