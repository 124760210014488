.user_image_edit_container {
	height: 150px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-sizing: border-box;
}

.user_image_edit_container > * {
	box-sizing: border-box;
}

/* Image Preview Container */
.user_image_edit_preview {
	position: relative;
	height: 100px;
	width: 100px;
}
/* Image Preview <img /> */
.user_image_edit_preview_img {
	display: block;
	height: 100px;
	width: 100px;
	border: 1px solid var(--main_ci_grey);
	border-radius: 15px;
}

/* Image Preview Control-Overlay */
.user_image_edit_overlay {
  position: absolute;
  top: 0;
  width: 101px;
  height: 101px;
  display: flex;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  transition: .2s ease-in-out;
  opacity:0;
}

.user_image_edit_preview:hover .user_image_edit_overlay {
	opacity: 1!important;
}

.user_image_edit_overlay_left,
.user_image_edit_overlay_right {
	width: 50%;
	height: 100%;
	display: flex;
	align-items:center;
	justify-content: center; 
	color: var(--main_ci_backgroundgrey);
	background-color: rgba(0,0,0,0.5);
	transition: all .2s ease;
}
.user_image_edit_overlay_left:hover,
.user_image_edit_overlay_right:hover {
	background-color: rgba(var(--main_ci_hover));
}

.user_image_edit_overlay_left {
	border-radius: 15px 0 0 15px;
}
.user_image_edit_overlay_right {
	border-radius: 0 15px 15px 0;
}


.user_image_edit_input_container {
	display: flex;
	flex-direction: column;

}

.user_image_edit_add_text {
	font-size: 11px;
}
.user_image_edit_add_error{
	margin: 20px 0 0 0;
	font-size: 12px;
	color: darkred;
}

.user_image_edit_input {
	margin: 10px 0 0 0;
	padding: 0;
	height: 20px;
}