/**
 * SimpleLoader.css
 * kbsoft-webapp
 * 
 * Created by Tim Flauger on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 * 
 */
.simple-spinner {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.simple-spinner-spinner {
        border: 7px solid var(--main_ci_grey);
        /* Light grey */
        border-top: 7px solid var(--main_ci_color);
        /* Blue */
        border-radius: 50%;
        width: 50px;
        height: 50px;
        animation: spin 1.2s linear infinite;
    
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}