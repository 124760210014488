/**
 * Topnav.css
 * kbsoft-webapp
 * 
 * Created by Christian Jungbluth on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 * 
 */

.frame_topnav_area {
    height: 50px;
    width: 100%;
    background: var(--main_ci_color);
    display: flex;
    flex-direction: row;
}

.topnav_title {
    height: 100%;
    width: 100px;
    font-size: 12px;
    color: white;
    display: flex;
    align-items: center;
    padding: 0 0 0 15px;
}

.topnav_navpoints {
    height: 100%;
    width: 100%;
    display: flex;
    align-self: center;
}

.topnav_navpoints a {
    height: 100%;
    width: 100px;
    font-size: 12px;
    text-decoration: none;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 0 0 0;
    box-sizing: border-box;
    border-bottom: 4px solid transparent;
}

.topnav_navpoints a:hover,
.topnav_navpoints a.active {
    box-sizing: border-box;
    border-bottom: 4px solid white;
    color: white;
}

.topnav_user_log {
    height: 100%;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.topnav_user_log a{
    text-decoration: none;
}

.topnav_logout{
    height: 100%;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.topnav_logout i{
    color: white;
    font-size: 20px;

}

.topnav_usermenu_container {
    position: relative;
}

.topnav_usermenu {
    position: absolute;
    width: 200px;
    top: 45px;
    right: 0;
    border: 1px solid #d9dce0;
    box-shadow: 0 0 5px #d9dce0;
}

.topnav_usermenu_navpoint {
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    background-color: #f1f4f7;
    color: #000;
    border-bottom: 1px solid #d9dce0;
    font-size: 12px;
}

.topnav_usermenu_navpoint_text {
    font-size: 10px;
}

.topnav_usermenu_navpoint_link:hover {
    background-color: var(--main_ci_hover);
}

.topnav_usermenu_navpoint:last-child {
    border: 0;
}