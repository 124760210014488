/**
 * Contact.css
 * kbsoft-webapp
 * 
 * Created by Christian Jungbluth on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 * 
 */

.contact_content_area{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.contact_content_sidebar{
    height: 100%;
    min-width: 250px;
    background: white;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
}

.contact_list_function{
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
}


.contact_sidebar_headline{
    width: 100%;
    height: 40px;
    font-size: 12px;
    color: var(--main_ci_grey_text);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0 0 10px;
    font-weight: bold;
}

.contact_function_btn{
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact_list{
    height: 100%;
    width: 100%;
    display: flex;
    overflow-y: scroll;
}

.contact_listitems{
    width: 100%;
}

.contact_listitem_text{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 15px 0 15px 10px;  
}

.contact_no_elements,
.contact_navpoint{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: var(--main_ci_grey_text);
    font-size: 12px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    padding: 5px 0 5px 0;
}

.contact_no_elements:last-child,
.contact_navpoint:last-child{
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.contact_navpoint:hover{
    background: var(--main_ci_backgroundgrey);
    cursor: pointer;
    color: var(--main_ci_grey_text);
}

.active_item{
    background: var(--main_ci_backgroundgrey);
}

.contact_content_view{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.contact_content_subnav{
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.contact_branch_select{
    margin: 0 10px 0 0;
}

.contact_branch_text{
    margin: 0 10px 0 0;
    height: 100%;
    width: 100px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 12px;
    color: var(--main_ci_grey_text);
}

.contact_branch_add{
    height: 100%;
    width: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.contact_contact_area{
    height: 100%;
    width: 100%;
    display: flex;
    overflow-y: scroll;
}

.contact_contact_cards{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;
}

.contact_singlecard_add{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact_contact_singlecard{
    height: 200px;
    width: 200px;
    margin: 0 0 10px 10px;
    background: white;
    box-shadow: 0 0  6px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
}

.contact_singlecard_top{
    height: 160px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact_singlecard_bottom{
    height: 40px;
    width: 100%;
    background: var(--main_ci_backgroundgrey);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.contact_singlecard_function{
    height: 100%;
    width: 100%;
    padding: 0 5px 0 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.contact_contact_information{
    width: 80%;
    height: 90%;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: var(--main_ci_grey_text);
    padding: 5px 0 0 0;   
}

.contact_information_row{
    height: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.contact_information_icon{
    height: 100%;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact_information_icon i{
    font-size: 14px;
}

.contact_information_text{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.contact_information_branch{
    font-style: italic;
    height: 12px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 0 0 20px 0;
}

.contact_information_name{
    height: 14px;
    width: 100%;
    display: flex;
    flex-direction: row;
    font-weight: bold;
    margin: 5px 0 0 0;
}