/**
 * MasterData.css
 * kbsoft-webapp
 * 
 * Created by Christian Jungbluth on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 * 
 */

.masterdata_content_area {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.masterdata_subnav_area {
    height: 40px;
    width: 100%;
    background: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
}

.masterdata_subnav_navpoint {
    height: 100%;
    width: 100%;
    display: flex;
    align-self: center;
}

.masterdata_subnav_navpoint a {
    height: 100%;
    width: 250px;
    font-size: 12px;
    text-decoration: none;
    color: var(--main_ci_grey_text);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 0 0 0;
    box-sizing: border-box;
    border-bottom: 1px solid transparent;
}

.masterdata_subnav_navpoint a:hover,
.masterdata_subnav_navpoint a.active {
    box-sizing: border-box;
    border-bottom: 1px solid var(--main_ci_grey_text);
    color: var(--main_ci_grey_text);
}

.masterdata_subcontent_area {
    height: 100%;
    width: 100%;
    display: flex;
}
