/**
 * Dashboard.css
 * kbsoft-webapp
 * 
 * Created by Christian Jungbluth on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 * 
 */

.dashboard_content_area{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 12px;  
}

.dashboard_greeting_text{
    margin: 20px 0 0 20px;
}