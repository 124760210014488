/**
 * Modal.css
 * kbsoft-webapp
 * 
 * Created by Christian Jungbluth on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 * 
 */

.modal_parent {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}



.modal {
    z-index: 1002;
    width: 600px;
    min-height: 200px;
    background: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 16px;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    display: flex;
    justify-content: center;
}

.modal>div {
    z-index: 1002;
}

.modal_area {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 80%;
}

.modal_content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.modal_headline {
    display: flex;
    justify-content: center;
    margin: 5px 0 5px 0;
    font-size: 16px;
    color: var(--main_ci_grey_text);
}

.modal_headline_warning {
    color:#000;
    background-color: #ff8000a5;
    font-size: 13px;
    margin:5px;
    padding: 10px;
}

.modal_functions {
    z-index: 1003;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 10px 0 10px 0;
}

.modal_content > input {
    display: block;
    width: 98%;
    height: 25px;
    font-size: 12px;
    margin: 0 0 10px 0;
    padding: 0 0 0 5px;
    border: none;
    border-bottom: 1px solid #d8d8d8;
    background: white;
    color: var(--main_ci_grey_text);
}

.modal_content > textarea {
    display: block;
    width: 99%;
    min-height: 50px;
    font-size: 12px;
    margin: 0 0 10px 0;
    padding: 5px 0 0 3px;
    resize: none;
    border: 1px solid #d8d8d8;
    font-family: 'Roboto';
    font-style: normal;
    color: var(--main_ci_grey_text);
}

.modal_content.small > textarea {
    height: 50px!important;
}

.modal_content.medium > textarea {
    height: 100px!important;
}

.modal_content.large > textarea {
    height: 120px!important;
}

.modal_content > select {
    display: block;
    width: 99%;
    height: 35px;
    font-size: 12px;
    margin: 0 0 10px 0;
    padding: 0 0 0 5px;
}

.modal_content > input:focus,
.modal_content > textarea:focus,
.modal_content > select:focus {
    outline: none;
}

.multiselect > label,
.modal_content > label {
    width: 100%;
    margin: 12px 0 5px 0;
    font-size: 12px;
    color: var(--main_ci_grey_text);
}

.modal_content_question {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 18px;
    color: var(--main_ci_grey_text);
    margin: 0 0 10px 0;
}

.input_group {
    margin: 5px 0 0 0;
}

.branch_add_row {
    height: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.modal_error_container {
    border: .5px solid #f5c6cb;
    padding: 5px 5px 5px 10px;
    display: flex;
    font-size: 12px;
    background-color: #f8d7da;
    color: #721c24;
}