/**
 * UserData.css
 * kbsoft-webapp
 * 
 * Created by Christian Jungbluth on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 * 
 */

.userdata_content_area {
    height: 100%;
    width: 100%;
    display: flex;
    overflow-y: scroll;
}

.userdata_user_cards {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px 0 0 10px;
}

.user_card {
    height: 200px;
    width: 200px;
    margin: 0 0 10px 10px;
    background: white;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
}

.user_card_top {
    height: 160px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.user_card_bottom {
    height: 40px;
    width: 100%;
    background: var(--main_ci_backgroundgrey);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.user_card_function {
    height: 100%;
    width: 100%;
    padding: 0 5px 0 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.user_card_add {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.user_card_pic {
    min-height: 90px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user_card_info {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.user_card_info_row {
    width: 100%;
    height: 15px;
    display: flex;
    flex-direction: row;
    font-size: 12px;
    color: var(--main_ci_grey_text);
}

.user_card_info_row_headline {
    width: 100%;
    height: 15px;
    display: flex;
    flex-direction: row;
    font-size: 12px;
    color: var(--main_ci_grey_text);
    font-weight: bold;
}

.user_picture_upload_area{
    height: 110px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.user_picture_pic{
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user_picture_upload_view{
    height: 60px;
    width: 60px;
    border-radius: 10px;
}

.user_picture_upload_btn{
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.user_modal_picture_container {
    border: 1px solid black;
    height: 220px;
}