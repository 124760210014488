/**
 * Login.css
 * kbsoft-webapp
 * 
 * Created by Christian Jungbluth on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 * 
 */

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #1f458a inset;

}

.login_content_area{
    height: 100vh;
    width: 100vw;
    background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(34,44,120,1) 0%, rgba(25,146,191,1) 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.login_input_area{
    height: 250px;
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.login_logo{
    height: 200px;
    width: 200px;
}

.login_input{
    height: 50px;
    width: 250px;
    background: #1f458a;
    border: none;
    color: white;
    font-size: 12px;
    padding: 0 0 0 10px;
    margin: 0 0 10px 0;
}

.login_input::placeholder{
    color: white;
    font-size: 12px;
}

.login_input:focus{
    outline: none;
}

.login_input:active{
    background: #222c78;
    outline: none;
}

.login_btn{
    height: 40px;
    width: 260px;
    border: none;
    background: white;
    color: #222c78;
    text-transform: uppercase;
    cursor: pointer;
}

.login_btn:hover{
    background: rgba(255, 255, 255, 0.808);
}

.login_passwort_missing{
    height: 20px;
    width: 260px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.login_passwort_missing_btn{
    font-size: 10px;
    color: white;
    text-decoration: none;
}