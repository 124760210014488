/**
 * ReviewGroups.css
 * kbsoft-webapp
 * 
 * Created by Christian Jungbluth on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 * 
 */

.reviewgroups_content_area{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    min-width: 1024px;
}

.reviewgroups_groups_stripe{
    height: 100%;
    width: 25%;
    background: white;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
}

.reviewgroups_groups_function{
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
}


.reviewgroups_stripe_name{
    width: 100%;
    height: 40px;
    font-size: 12px;
    color: var(--main_ci_grey_text);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0 0 10px;
    font-weight: bold;
}

.reviewgroups_function_btn{
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reviewgroups_groups_list{
    height: 100%;
    width: 100%;
    display: flex;
    overflow-y: scroll;
}

.reviewgroups_groups_listitems {
    width: 100%;
}

.reviewgroups_listitem_text{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 15px 0 15px 10px;   
}

.reviewgroups_group_navpoint,
.reviewgroups_group_navpoint_no_elements {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: var(--main_ci_grey_text);
    font-size: 12px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.reviewgroups_group_navpoint_no_elements {
    font-style: italic;
}

.reviewgroups_group_navpoint:last-child,
.reviewgroups_group_navpoint_no_elements:last-child {
    border-bottom:1px solid rgba(0, 0, 0, 0.2);
}

.active_item{
    background: var(--main_ci_backgroundgrey);
}

.reviewgroups_listitem_function{
    width: 50px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Dropdown */
.reviewgroups_listitem_function_dropdown {
    position: absolute;
    display: inline-block;
}

.reviewgroups_listitem_function_dropdown_button {

}

.reviewgroups_listitem_function_dropdown_content {
    position: absolute;
    z-index: 2;
    display: none;
    background-color: #fff;
    box-shadow: 0 0 10px 0px #0000001f;
}

.reviewgroups_listitem_function_dropdown_content_item {
    height: 25px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 20px 0 10px;
}

.reviewgroups_listitem_function_dropdown_content_item:hover {
    background-color: var(--main_ci_backgroundgrey);
    color: #000;
}

.reviewgroups_listitem_function_dropdown:hover
.reviewgroups_listitem_function_dropdown_content {
    display:block;
}

.drag_active {
    background-color: #222c78!important;
    color: #fff!important;
    padding: 5px 0 5px 0;
}


.drop_group_invalid {
    opacity: 0.1 !important;
}